import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import New from "../assets/img/product/New.jpg";
import CurrencyFormat from "react-currency-format";
import Footer from "../Components/Footer";
import { useStateValue } from "../StateProvider";
import HeaderScroll from "../Components/HeaderScroll";

function Cart() {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const [{ basket }, dispatch] = useStateValue();

  var totalCartPrice = 0;
  for (var i = 0; i < basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice;
  }

  const removeFromBasket = (id) => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav className="breadcrumb-section theme1 bg-lighten2 pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">cart</h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    cart
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <section className="whish-list-section theme1 pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="title mb-30 pb-25 text-capitalize">
                  Your cart items
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center" scope="col">
                          Product Image
                        </th>
                        <th className="text-center" scope="col">
                          Product Name
                        </th>
                        <th className="text-center" scope="col">
                          Stock Status
                        </th>
                        {/* <th className="text-center" scope="col">
                          Qty
                        </th> */}
                        <th className="text-center" scope="col">
                          Total Price
                        </th>
                        <th className="text-center" scope="col">
                          action
                        </th>
                      </tr>
                    </thead>
                    {basket.length >= 1 ? (
                      <tbody>
                        {basket.map((product) => (
                          <tr>
                            <th className="text-center" scope="row">
                              <img src={product.imgUrl} alt="img" />
                            </th>
                            <td className="text-center">
                              <span className="whish-title">
                                {product.title}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="badge badge-danger position-static">
                                In Stock
                              </span>
                            </td>
                            {/* <td className="text-center">
                              <div className="product-count style">
                                <div className="count d-flex justify-content-center">
                                  <input
                                    type="number"
                                    min={1}
                                    max={10}
                                    step={1}
                                    defaultValue={1}
                                  />
                                  <div className="button-group">
                                    <button className="count-btn increment">
                                      <i className="fas fa-chevron-up" />
                                    </button>
                                    <button className="count-btn decrement">
                                      <i className="fas fa-chevron-down" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td> */}
                            <td className="text-center">
                              <span className="whish-list-price">
                                ₹{product.discountedPrice}
                              </span>
                            </td>
                            <td className="text-center">
                              {" "}
                              <span className="trash">
                                <i
                                  onClick={() => removeFromBasket(product.id)}
                                  className="fas fa-trash-alt"
                                />{" "}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : null}
                  </table>
                  {basket.length == 0 ? (
                    <p style={{ fontSize: "25px", textAlign: "center" }}>
                      Cart is Empty :-(
                    </p>
                  ) : null}
                </div>
                <div style={{ float: "right", marginTop: "30px" }}>
                  <CurrencyFormat
                    renderText={(value) => (
                      <>
                        <h4>
                          Subtotal ({basket.length} item(s)):{" "}
                          <strong>₹ {totalCartPrice}</strong>
                        </h4>
                      </>
                    )}
                    decimalScale={2}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₹"}
                    value={0}
                  />
                  <hr />
                  {basket.length >= 1 ? (
                    <Link
                      className="btn"
                      style={{
                        border: "1px solid gray",
                        float: "right",
                        marginTop: "10px",
                        color: "white",
                        backgroundColor: "purple",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "40px",
                      }}
                      to="/checkout"
                    >
                      Proceed to Checkout
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product tab end */}
        <Footer />
      </div>
    </div>
  );
}

export default Cart;

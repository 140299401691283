import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useStateValue } from "../StateProvider";
import HeaderScroll from "../Components/HeaderScroll";
import { db, auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import firebase from "firebase/compat/app";

function Account() {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const navigate = useNavigate();
  const [name, setName] = useState("");
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setName(authUser.displayName);
      } else {
        navigate("/login");
      }
    });
  }, []);

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const reauthenticateUser = (currentPassword) => {
    var user = auth.currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  const changePassword = (event) => {
    event.preventDefault();

    reauthenticateUser(event.target.currentPassword.value)
      .then(() => {
        if (
          event.target.newPassword.value === event.target.newRePassword.value
        ) {
          var user = auth.currentUser;
          user
            .updatePassword(event.target.newPassword.value)
            .then(() => {
              handleClose();
              document.getElementById("password-form").reset();
              alert("Password Changed Successfully");
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
            alert("Passwords don't match")
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="password-form" className="p-0" onSubmit={changePassword}>
            <div className="form-group row">
              <div className="col-12 mb-15">
                <input
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Current Password"
                  required
                />
              </div>
              <div className="col-12 mb-15">
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  required
                />
              </div>
              <div className="col-12">
                <input
                  type="password"
                  className="form-control"
                  id="newRePassword"
                  name="newRePassword"
                  placeholder="Re-enter New Password"
                  required
                />
              </div>
            </div>
            <div className="form-group row mb-10 mt-5">
              <div className="col-12 text-right">
                <button
                  style={{
                    backgroundColor: "#d9ffd8",
                    color: "white",
                    padding: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    fontSize: "19px",
                    color: "#56c644",
                    fontWeight: "bold",
                    borderRadius: "15px",
                  }}
                >
                  <i className="fa fa-lock pr-3" />
                  Change Password
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav className="breadcrumb-section theme1 bg-lighten2 pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">
                    My Account
                  </h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Account
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <section className="whish-list-section theme1 pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title mb-30 pb-25 text-capitalize">
                  Hi {name}!
                </h2>
              </div>
            </div>
            <div
              className="row"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingRight: "17px",
              }}
            >
              <div
                className="col-12 col-lg-4"
                style={{ border: "1.5px solid gray", margin: "10px" }}
              >
                <h4
                  className="mt-20 mb-15 pb-10 text-capitalize"
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid #ebebeb",
                  }}
                >
                  Change your Password
                </h4>
                <p style={{ fontSize: "18px" }} className="mt-25 mb-25">
                  You can change your account password here.
                </p>
                <button
                  className="btn mb-20"
                  style={{ border: "1px solid red" }}
                  onClick={handleShow}
                >
                  Change password
                </button>
              </div>
              <div
                className="col-12 col-lg-3"
                style={{ border: "1px solid gray", margin: "10px" }}
              >
                <h4
                  className="mt-15 mb-15 pb-10 text-capitalize"
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid #ebebeb",
                  }}
                >
                  View your Wishlist
                </h4>
                <p style={{ fontSize: "18px" }} className="mt-25 mb-25">
                  View your favourite products.
                </p>
                <Link
                  className="btn mb-20"
                  style={{ border: "1px solid red" }}
                  to="/wishlist"
                >
                  View Wishlist
                </Link>
              </div>
              <div
                className="col-12 col-lg-4"
                style={{ border: "1px solid gray", margin: "10px" }}
              >
                <h4
                  className="mt-15 mb-15 pb-10 text-capitalize"
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid #ebebeb",
                  }}
                >
                  View Orders
                </h4>
                <p style={{ fontSize: "18px" }} className="mt-25 mb-25">
                  View your orders and their status.
                </p>
                <Link
                  className="btn mb-20"
                  style={{ border: "1px solid red" }}
                  to="/my-orders"
                >
                  View orders
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* product tab end */}
        <Footer />
      </div>
    </div>
  );
}

export default Account;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home";
import Login from "./Screens/Login";
import Checkout from "./Screens/Checkout";
import Cart from "./Screens/Cart";
import Wishlist from "./Screens/Wishlist";
import Contact from "./Screens/Contact";
import Shop from "./Screens/Shop";
import Shop2 from "./Screens/Shop2";
import ProductSingle from "./Screens/ProductSingle";
import Proteins from "./Screens/Collections/Proteins";
import Aminos from "./Screens/Collections/Aminos";
import PreWorkouts from "./Screens/Collections/Pre-Workouts";
import Gainers from "./Screens/Collections/Gainers";
import Orders from "./Screens/Orders";
import About from "./Screens/About";
import Account from "./Screens/Account";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import TermsAndConditions from "./Screens/TermsAndConditions";
import ReturnPolicy from "./Screens/ReturnPolicy";
import Data from './Data/products.json'
import './App.css'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/shop/2" element={<Shop2 />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/my-account" element={<Account />} />
        <Route exact path="/my-orders" element={<Orders />} />
        <Route exact path="/collection/proteins" element={<Proteins />} />
        <Route exact path="/collection/aminos" element={<Aminos />} />
        <Route exact path="/collection/gainers" element={<Gainers />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/refund-return-policy" element={<ReturnPolicy />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />
        <Route
          exact
          path="/collection/pre-workouts"
          element={<PreWorkouts />}
        />
        {Data.map((product) => (
          <Route
            key={product.id}
            exact
            path={`/products/${product.id}`}
            element={<ProductSingle contents={product} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

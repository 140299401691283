import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    // bmBurgerBars: {
    //   background: "#373a47",
    // },
    // bmBurgerBarsHover: {
    //   background: "#a90000",
    // },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setShow(false);
      } else {
        setShow(true);
      }
    });
  }, []);

  const signOutUser = () => {
    auth.signOut().then(() => {
      navigate("/");
      window.location.reload();
    });
  };

  const [{ basket, wishlist }, dispatch] = useStateValue()

  var totalCartPrice = 0;
  for(var i = 0; i<basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice
  }

  

  return (
    <div className="Krishna-Main">
      <Menu isOpen={isMenuOpen} styles={styles}>
        <Link
          to="/"
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        >
          <img
            src={logoW}
            alt="logo"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "140px"
            }}
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textDecoration: "none",
            color: "white",
            marginTop: "35px",
          }}
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <i className="fa fa-home" />
            <Link
              id="home"
              className="menu-item"
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              Home
            </Link>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <i className="fa fa-shopping-cart" />
            <Link
              id="shop"
              className="menu-item"
              to="/shop"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              Shop
            </Link>
          </div>
          {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <i class="fas fa-prescription-bottle" />
            <Link
              id="collections"
              className="menu-item"
              to="/collection"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
              }}
            >
              Collections
            </Link>
          </div> */}
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <i className="fa fa-building" />
            <Link
              id="about"
              to="/about"
              className="menu-item"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              About
            </Link>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <i className="fa fa-phone" />
            <Link
              id="contact"
              to="/contact"
              className="menu-item"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              Contact Us
            </Link>
          </div>
          <div style={{ marginTop: "75px", marginBottom: "10px" }}>
            <i className="fa fa-phone-square-alt" />
            <span
              id="contact"
              className="menu-item"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "16px",
              }}
            >
              +91 9306293768
            </span>
          </div>
          <div style={{ marginTop: "5px", marginBottom: "10px" }}>
            <i className="fa fa-envelope" />
            <span
              id="contact"
              className="menu-item"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "10px",
                fontSize: "16px",
              }}
            >
              care@refuellabz.com
            </span>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <a
              style={{ marginLeft: "5px", marginRight: "10px" }}
              href="https://www.instagram.com/refuel_labz/"
            >
              <i className="fab fa-instagram" style={{ color: "white" }} />
            </a>
            <a
              style={{ marginLeft: "10px", marginRight: "10px" }}
              href="https://www.facebook.com/refuellabz"
            >
              <i className="fab fa-facebook" style={{ color: "white" }} />
            </a>
            <a
              style={{ marginLeft: "10px", marginRight: "5px" }}
              href="https://twitter.com/Refuellabz"
            >
              <i className="fab fa-twitter" style={{ color: "white" }} />
            </a>
            <a
              style={{ marginLeft: "10px", marginRight: "5px" }}
              href="https://www.amazon.in/s?me=AYQYUW16VWCXY&marketplaceID=A21TJRUUN4KGV"
            >
              <i className="fab fa-amazon" style={{ color: "white" }} />
            </a>
          </div>
        </div>
      </Menu>
      <div>
        {/* header start */}
        <header>
          {/* header top start */}
          <div className="header-top theme1 bg-dark pt-15">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-7 order-last order-md-first">
                  <div className="static-info text-center text-md-left">
                    <p className="text-white" stye={{ marginBottom: "0px" }}>
                      Order now and get{" "}
                      <span className="theme-color"> 20% off</span>!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* header top end */}
          {/* header-middle satrt */}
          <div className="header-middle pt-20">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-6 col-lg-2 order-first">
                  <div className="logo text-center text-sm-left mb-30 mb-sm-0">
                    <Link to="/">
                      <img src={logoDark} style={{width:"130px"}} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-5 col-xl-4">
                  {/* search-form end */}
                  <div className="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <div className="media static-media mr-50 d-none d-lg-flex">
                      <img
                        className="mr-3 align-self-center"
                        src={icon1}
                        alt="icon"
                      />
                      <div className="media-body">
                        <div className="phone">
                          <span className="text-muted">Call us:</span>
                        </div>
                        <div className="phone">
                          <a href="tel:+919306293768" className="text-dark">
                            9306293768
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-menu-toggle theme1 d-lg-none">
                      <a
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="offcanvas-toggle"
                      >
                        <svg viewBox="0 0 800 600">
                          <path
                            d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                            id="top"
                          />
                          <path d="M300,320 L540,320" id="middle" />
                          <path
                            d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                            id="bottom"
                            transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                          />
                        </svg>
                      </a>
                    </div>
                    {/* static-media end */}
                    <div className="cart-block-links theme1">
                      <ul
                        className="d-flex"
                        style={{ paddingLeft: "1rem", marginBottom: "0px" }}
                      >
                        <li style={{ marginRight: "15px" }}>
                          <a href="#">
                            <span className="position-relative">
                              <Dropdown align={{ lg: "end" }}>
                                <Dropdown.Toggle
                                  style={{
                                    background: "none",
                                    border: "none",
                                    flex: 1,
                                    width: "100%",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                  align={{ lg: "end" }}
                                >
                                  <button className="category-toggle">
                                    <i
                                      className="far fa-user-circle"
                                      style={{
                                        color: "black",
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                    />
                                  </button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {show ? (
                                    <div>
                                      <Dropdown.Item>
                                        <Link to="/login">Login</Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link to="/login">Register</Link>
                                      </Dropdown.Item>
                                    </div>
                                  ) : (
                                    <div>
                                      <Dropdown.Item>
                                        <Link to="/my-account">My Account</Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link to="/my-orders">My Orders</Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={signOutUser}>
                                        Sign Out
                                      </Dropdown.Item>
                                    </div>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </a>
                        </li>
                        <li>
                          <Link className="offcanvas-toggle" to="/wishlist">
                            <span className="position-relative">
                              <i className="icon-heart" />
                              <span className="badge cbdg1">
                                {wishlist?.length}
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li className="mr-0 cart-block position-relative">
                          <Link className="offcanvas-toggle" to="/cart">
                            <span className="position-relative">
                              <i className="fa fa-shopping-cart" />
                              <span className="badge cbdg1">
                                {basket?.length}
                              </span>
                            </span>
                            <span className="cart-total position-relative">
                              ₹ {totalCartPrice}
                            </span>
                          </Link>
                        </li>
                        {/* cart block end */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-xl-6 order-lg-first">
                  <div className="mobile-category-nav theme1  py-20">
                    {/*=======  category menu  =======*/}
                    <div className="hero-side-category">
                      {/* Category Toggle Wrap */}
                      <div className="category-toggle-wrap">
                        {/* Category Toggle */}
                        {/* <button className="category-toggle">
                          <i className="fa fa-bars" /> All Categories
                        </button> */}
                        <Dropdown align={{ lg: "end" }}>
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                              flex: 1,
                              width: "100%",
                            }}
                            align={{ lg: "end" }}
                          >
                            <button className="category-toggle">
                              <i className="fa fa-list-ul" /> All Categories
                            </button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="/collection/proteins">
                              Proteins
                            </Dropdown.Item>
                            <Dropdown.Item href="/collection/gainers">
                              Gainers
                            </Dropdown.Item>
                            <Dropdown.Item href="/collection/pre-workouts">
                              Pre-Workouts
                            </Dropdown.Item>
                            <Dropdown.Item href="/collection/aminos">
                              Aminos
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {/* Category Menu */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* header-middle end */}
          {/* header bottom start */}
          <nav id="sticky" className="header-bottom theme1 d-none d-lg-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-10 offset-lg-2 d-flex flex-wrap align-items-center position-relative">
                  <ul className="main-menu d-flex">
                    <li className="active ml-0">
                      <Link to="/" className="pl-0">
                        Home
                      </Link>
                    </li>
                    <li className="position-static">
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <a href="#">
                        Collection <i className="ion-ios-arrow-down" />
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/collection/proteins">Proteins</Link>
                        </li>
                        <li>
                          <Link to="/collection/gainers">Gainers</Link>
                        </li>
                        <li>
                          <Link to="/collection/pre-workouts">
                            Pre-Workouts
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/collection/aminos">Aminos</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          {/* header bottom end */}

          {/*=======  End of category menu =======*/}
        </header>
      </div>
    </div>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { getAuth, updateProfile } from "firebase/auth";
import { db, auth } from "../firebase";
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import HeaderScroll from "../Components/HeaderScroll";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "red",
    boxShadow: "none",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Login() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;
  

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authy = getAuth();

  const loginUser = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(
        e.target.mail.value,
        e.target.password.value
      )
      .then((auth) => {
        console.log(auth);
        if (auth) {
          navigate("/");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const registerUser = (e) => {
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(
        e.target.mail.value,
        e.target.password.value
      )
      .then((auth) => {
        if (auth) {
          updateProfile(authy.currentUser, {
            displayName: e.target.name.value,
          }).then(() => {
              console.log(auth)
            navigate("/");
          });
        }
      })
      .catch((error) => {
        alert(error.message);
        console.log(error);
      });
  };

  const forgotPassword = (e) => {
    alert(
      "The password reset feature is under development currently. Please contact +91 9667801022 to get your password reset."
    );
    // if (email == "") {
    //   alert("Please enter your email address first");
    // } else {
    //   auth.sendPasswordResetEmail(email);
    // }
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div className="Krishna-Main">
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <div>
          <nav className="breadcrumb-section theme1 bg-lighten2 pt-70 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title text-center mb-15">
                    <h2 className="title text-dark text-capitalize">login</h2>
                  </div>
                </div>
                <div className="col-12">
                  <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Log in to your account{" "}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </nav>
          {/* breadcrumb-section end */}
          {/* product tab start */}
          <Paper className={classes.root} style={{ marginTop: "10px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              // indicatorColor="primary"
              TabIndicatorProps={{ style: { background: "red" } }}
              centered
            >
              <Tab label="Login" />
              <Tab label="Register" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div
                className=""
                style={{
                  maxWidth: "50rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  className="my-account pt-40 pb-40 pl-lg-40 pr-lg-40"
                  style={{
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                    border: "1px solid rgb(0 0 0 / 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="tt h2 text-capitalize mb-30 pb-25">
                          {" "}
                          Log in to your account
                        </h3>
                        <form
                          className="log-in-form"
                          style={{ border: "none" }}
                          id="newForm"
                          onSubmit={loginUser}
                        >
                          <div className="form-group row">
                            {/* <div className="col-md-3">

                            <label
                              htmlFor="staticEmail"
                              className=" col-form-label"
                              style={{marginleft:'auto'}}
                            >
                              Email
                            </label>
                              </div> */}
                            <div
                              className="col-md-8"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <input
                                type="email"
                                className="form-control"
                                id="staticEmail"
                                name="mail"
                                placeholder="E-mail"
                                // value={email}
                                // onChange={(e)=>setEmail(e.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            {/* <label
                              htmlFor="inputPassword"
                              className="col-md-3 col-form-label"
                            >
                              Password
                            </label> */}
                            <div
                              className="col-md-8"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <div className="input-group mb-2 mr-sm-2">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="inputPassword"
                                  name="password"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group row pb-3 text-center">
                            <div className="col-md-6 offset-md-3">
                              <div className="login-form-links">
                                <div className="sign-btn">
                                  <button
                                    style={{
                                      backgroundColor: "#fdd8d8",
                                      color: "white",
                                      padding: "10px",
                                      paddingLeft: "40px",
                                      paddingRight: "40px",
                                      marginBottom: "10px",
                                      fontSize: "19px",
                                      color: "#e56d6d",
                                      fontWeight: "bold",
                                      borderRadius: "15px",
                                    }}
                                    type="submit"
                                  >
                                    Sign In
                                  </button>
                                  <button
                                    className="btn"
                                    style={{ fontSize: "13px" }}
                                    onClick={forgotPassword}
                                  >
                                    Forgot Password?
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row text-center mb-0">
                            <div className="col-12">
                              <div className="border-top">
                                <button
                                  className="pt-20 "
                                  onClick={() => setValue(1)}
                                >
                                  New to Refuel? Create an account here
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div
                className=""
                style={{
                  maxWidth: "50rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  className="my-account pt-40 pb-40 pl-lg-40 pr-lg-40"
                  style={{
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                    border: "1px solid rgb(0 0 0 / 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="tt h2 text-capitalize mb-30 pb-25">
                          {" "}
                          Create an Account
                        </h3>
                        <form
                          className="log-in-form"
                          style={{ border: "none" }}
                          id="newForm"
                          onSubmit={registerUser}
                        >
                          <div className="form-group row">
                            {/* <div className="col-md-3">

                            <label
                              htmlFor="staticEmail"
                              className=" col-form-label"
                              style={{marginleft:'auto'}}
                            >
                              Email
                            </label>
                              </div> */}
                            <div
                              className="col-md-8"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Full Name"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            {/* <div className="col-md-3">

                            <label
                              htmlFor="staticEmail"
                              className=" col-form-label"
                              style={{marginleft:'auto'}}
                            >
                              Email
                            </label>
                              </div> */}
                            <div
                              className="col-md-8"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <input
                                type="email"
                                className="form-control"
                                id="staticEmail"
                                name="mail"
                                placeholder="E-mail"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            {/* <label
                              htmlFor="inputPassword"
                              className="col-md-3 col-form-label"
                            >
                              Password
                            </label> */}
                            <div
                              className="col-md-8"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <div className="input-group mb-2 mr-sm-2">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="inputPassword"
                                  name="password"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group row pb-3 text-center">
                            <div className="col-md-6 offset-md-3">
                              <div className="login-form-links">
                                <div className="sign-btn">
                                  <button
                                    style={{
                                      backgroundColor: "#fdd8d8",
                                      color: "white",
                                      padding: "10px",
                                      paddingLeft: "40px",
                                      paddingRight: "40px",
                                      fontSize: "19px",
                                      color: "#e56d6d",
                                      fontWeight: "bold",
                                      borderRadius: "15px",
                                    }}
                                    type="submit"
                                  >
                                    Register
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row text-center mb-0">
                            <div className="col-12">
                              <div className="border-top">
                                <button
                                  className="pt-20 "
                                  onClick={() => setValue(0)}
                                >
                                  Already have an account? Sign in here
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Paper>

          {/* product tab end */}
          {/* footer strat */}
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import logoDark from "../assets/img/logo/logoDark.png";
import phone from '../assets/img/icon/phone.png';
import payment from '../assets/img/payment/payment.png';

function Footer() {
  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;

  return (
    <div>
      <footer className="bg-light theme1 position-relative">
        {/* footer bottom start */}
        <div className="footer-bottom pt-80 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 mb-30">
                <div className="footer-widget mx-w-400">
                  <div className="footer-logo mb-35">
                    <Link to="/">
                      <img src={logoDark} alt="footer logo" />
                    </Link>
                  </div>
                  <div className="address-widget mb-30">
                    <div className="media">
                      <span className="address-icon mr-3">
                        <img src={phone} alt="phone" />
                      </span>
                      <div className="media-body">
                        <p className="help-text text-uppercase">NEED HELP?</p>
                        <h4 className="title text-dark">
                          <a href="tel:9306293768">+91 9306293768</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="social-network">
                    <ul className="d-flex">
                      <li>
                        <a
                          href="https://www.facebook.com/refuellabz"
                          target="_blank"
                        >
                          <span className="icon-social-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Refuellabz"
                          target="_blank"
                        >
                          <span className="icon-social-twitter" />
                        </a>
                      </li>
                      <li className="mr-2">
                        <a
                          href="https://www.instagram.com/refuel_labz/"
                          target="_blank"
                        >
                          <span className="icon-social-instagram" />
                        </a>
                      </li>
                      <li className="mr-0">
                        <a
                          href="https://www.amazon.in/s?me=AYQYUW16VWCXY&marketplaceID=A21TJRUUN4KGV"
                          target="_blank"
                        >
                          <i className="fab fa-amazon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 mb-30">
                <div className="footer-widget">
                  <div className="border-bottom cbb1 mb-25">
                    <div className="section-title pb-20">
                      <h2 className="title text-dark text-uppercase">
                        Links
                      </h2>
                    </div>
                  </div>
                  {/* footer-menu start */}
                  <ul className="footer-menu">
                    <li>
                      <a href="/my-account">My Account</a>
                    </li>
                    <li>
                      <a href="/my-orders">My Orders</a>
                    </li>
                    <li>
                      <a href="/about">About Us</a>
                    </li>
                    <li>
                      <a href="/contact">Contact us</a>
                    </li>
                  </ul>
                  {/* footer-menu end */}
                </div>
              </div>
              <div className="col-12 col-md-4 mb-30">
                <div className="footer-widget">
                  <div className="border-bottom cbb1 mb-25">
                    <div className="section-title pb-20">
                      <h2 className="title text-dark text-uppercase">
                        Terms &amp; Policies
                      </h2>
                    </div>
                  </div>
                  {/* footer-menu start */}
                  <ul className="footer-menu">
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/terms-and-conditions">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="/refund-return-policy">Return &amp; Refund Policy</a>
                    </li>
                  </ul>
                  {/* footer-menu end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer bottom end */}
        {/* coppy-right start */}
        <div className="coppy-right pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="text-left">
                  <p className="mb-3 mb-md-0">
                    Copyright © 2023 Refuel Labz. 
                    {/* All Rights Reserved. <br />
                    Website developed by{" "}
                    <a href="https://webgit.co.in">
                      WebGit Infotech
                    </a> */}
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-8"
                style={{ marginTop: "10px" }}
              >
                <div className="text-left">
                  <img src={payment} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* coppy-right end */}
      </footer>
    </div>
  );
}

export default Footer;

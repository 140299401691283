import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateValue } from '../StateProvider'
import { Link } from "react-router-dom";

function Product({ id, imgUrl, title, originalPrice, discountedPrice, productUrl }) {
    const [{ basket }, dispatch] = useStateValue()

    const addToBasket = () => {
        dispatch({
          type: "ADD_TO_BASKET",
          item: {
            id: id,
            imgUrl: imgUrl,
            title: title,
            originalPrice: originalPrice,
            discountedPrice: discountedPrice,
            productUrl: productUrl,
          },
        });
    }

    const addToWishlist = () => {
      dispatch({
        type: "ADD_TO_WISHLIST",
        item: {
          id: id,
          imgUrl: imgUrl,
          title: title,
          originalPrice: originalPrice,
          discountedPrice: discountedPrice,
          productUrl: productUrl,
        },
      });
    }
  return (
    <div>
      <div>
        <div className="product-card" >
          <div className="card-body p-0">
            <div className="media flex-column">
              <div className="product-thumbnail position-relative">
                <span className="badge badge-danger top-right">New</span>
                <Link to={productUrl}>
                  <img className="first-img" src={imgUrl} alt="thumbnail" />
                </Link>
                {/* product links */}
                <ul className="product-links d-flex justify-content-center">
                  <li>
                    <a onClick={addToWishlist}>
                      <span
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="add to wishlist"
                        className="icon-heart"
                      ></span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={addToBasket}
                      data-toggle="modal"
                      data-target="#quick-view"
                    >
                      <span
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Add to cart"
                        className="icon-basket"
                      />
                    </a>
                  </li>
                </ul>
                {/* product links end*/}
              </div>
              <div className="media-body">
                <div className="product-desc">
                  <h3 className="title">
                    <Link to={productUrl} style={{ textDecoration: "none" }}>
                      {title}
                    </Link>
                  </h3>
                  <div className="star-rating">
                    <span className="ion-ios-star" />
                    <span className="ion-ios-star" />
                    <span className="ion-ios-star" />
                    <span className="ion-ios-star" />
                    {/* <span class="ion-ios-star de-selected"></span> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="product-price">
                      <del className="del">₹ {originalPrice}</del>
                      <span className="onsale">₹ {discountedPrice}</span>
                    </h6>
                    <button
                      className="pro-btn"
                      data-toggle="modal"
                      data-target="#add-to-cart"
                      onClick={addToBasket}
                    >
                      <i className="icon-basket" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* product-list End */}
      </div>
    </div>
  );
}

export default Product;

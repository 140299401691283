import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNkf3XElWi_b0ePyM1MQZdpI52qn1OLvI",
  authDomain: "refuel-labz-fin.firebaseapp.com",
  projectId: "refuel-labz-fin",
  storageBucket: "refuel-labz-fin.appspot.com",
  messagingSenderId: "824279436138",
  appId: "1:824279436138:web:cf4713a5429d43a284b313",
  measurementId: "G-V3WK97CS7Z",
};

let app;
if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();

export { db, auth };

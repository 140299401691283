import React, { useState, useEffect } from "react";
import "../../App.css";
import "../../assets/css/fontawesome.min.css";
import "../../assets/css/ionicons.min.css";
import "../../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/plugins/plugins.css";
import "../../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../Components/Header";
import New from "../../assets/img/product/New.jpg";
import Footer from "../../Components/Footer";
import { useStateValue } from "../../StateProvider";
import HeaderScroll from "../../Components/HeaderScroll";
import Data from "../../Data/products.json";
import Product from "../../Components/Product";
import ProductNew from "../../Components/ProductNew";

function Gainers() {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const [{ basket }, dispatch] = useStateValue();

  var totalCartPrice = 0;
  for (var i = 0; i < basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice;
  }

  const removeFromBasket = (id) => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  var products = [];
  for (var i = 0; i < Data.length; i++) {
    if (Data[i].category[0] == "Gainers") {
      products.push(Data[i]);
    }
  }

  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav className="breadcrumb-section theme1 bg-lighten2 pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">Gainers</h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Collection
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Gainers
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <div className="product-tab bg-white pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-30">
                {/* product-tab-nav end */}
                <div className="tab-content" id="pills-tabContent">
                  {/* first tab-pane */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row grid-view theme1">
                      {products.map((product) => {
                        return (
                          product.new ? (
                            <div className="product-list mb-30 col-lg-3 p-10">
                              <ProductNew
                                id={product.id}
                                imgUrl={product.image[0]}
                                title={product.name}
                                originalPrice={product.price}
                                discountedPrice={
                                  product.price -
                                  ((product.price / 100) * 20).toFixed(0)
                                }
                                productUrl={`/products/${product.id}`}
                              />
                            </div>
                          ): (
                            <div className="product-list mb-30 col-lg-3 p-10">
                              <Product
                                id={product.id}
                                imgUrl={product.image[0]}
                                title={product.name}
                                originalPrice={product.price}
                                discountedPrice={
                                  product.price -
                                  ((product.price / 100) * 20).toFixed(0)
                                }
                                productUrl={`/products/${product.id}`}
                              />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                  {/* second tab-pane */}
                </div>
                {/* <div className="row">
                  <div className="col-12">
                    <nav className="pagination-section mt-30">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <ul className="pagination justify-content-center">
                            <li className="page-item active">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <i className="ion-chevron-right" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Gainers;
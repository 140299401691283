import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import New from "../assets/img/product/New.jpg";
import CurrencyFormat from "react-currency-format";
import Footer from "../Components/Footer";
import { useStateValue } from "../StateProvider";
import HeaderScroll from "../Components/HeaderScroll";

function ProductSingle({contents}) {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const [{ basket }, dispatch] = useStateValue();
  const [added, setAdded] = useState(false);


  var totalCartPrice = 0;
  for (var i = 0; i < basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice;
  }

    const addToBasket = () => {
      dispatch({
        type: "ADD_TO_BASKET",
        item: {
          id: contents.id,
          imgUrl: contents.image[0],
          title: contents.name,
          originalPrice: contents.price,
          discountedPrice:
          contents.price - ((contents.price / 100) * 20).toFixed(0),
          productUrl: `/products/${contents.id}`,
        },
      });
      setAdded(true)
    };

    const addToWishlist = () => {
      dispatch({
        type: "ADD_TO_WISHLIST",
        item: {
          id: contents.id,
          imgUrl: contents.image[0],
          title: contents.name,
          originalPrice: contents.price,
          discountedPrice:
            contents.price - ((contents.price / 100) * 20).toFixed(0),
          productUrl: `/products/${contents.id}`,
        },
      });
    };


    useEffect(() => {

        if (basket.some((e) => e.title === contents.name)) {
            setAdded(true);
        } else {
            setAdded(false);
        }
    }, [])

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  var desc = contents.shortDescription
  var s = desc.split("~n").join("<br>");
  desc = s



  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav
          className="breadcrumb-section theme1 bg-lighten2"
          style={{ paddingTop: "70px", paddingBottom: "70px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">Product</h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/shop">Shop</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Product
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>

        <div>
          <section className="product-single theme3 pt-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="position-relative">
                    {contents.new?(<span className="badge badge-danger top-right">New</span>):null}
                  </div>
                  <div className="product-sync-init mb-20">
                    <div className="single-product">
                      <div className="product-thumb">
                        <img src={contents.image[0]} alt="product-thumb" />
                      </div>
                    </div>
                    {/* single-product end */}
                  </div>
                  <div className="product-sync-nav single-product">
                    {/* single-product end */}
                  </div>
                </div>
                <div className="col-lg-6 mt-5 mt-md-0">
                  <div className="single-product-info">
                    <div className="single-product-head">
                      <h2 className="title mb-20">{contents.name}</h2>
                      <div className="star-content mb-20">
                        <span className="star-on">
                          <i className="ion-ios-star" />{" "}
                        </span>
                        <span className="star-on">
                          <i className="ion-ios-star" />{" "}
                        </span>
                        <span className="star-on">
                          <i className="ion-ios-star" />{" "}
                        </span>
                        <span className="star-on">
                          <i className="ion-ios-star" />{" "}
                        </span>
                        <span className="star-on">
                          <i className="ion-ios-star" />{" "}
                        </span>
                      </div>
                    </div>
                    <div className="product-body mb-40">
                      {
                        contents.new?(
                          <div className="d-flex align-items-center mb-30">
                            <h6 className="product-price mr-20">
                              <del className="del">₹ {contents.price}</del>
                              <span className="onsale">
                                ₹{" "}
                                {contents.price -
                                  ((contents.price / 100) * 20).toFixed(0)}
                              </span>
                            </h6>
                            <span className="badge position-static bg-dark rounded-0">
                              Save 20%
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center mb-30">
                            <h6 className="product-price mr-20">
                              <span className="onsale">
                                Out of Stock
                              </span>
                            </h6>
                          </div>
                        )
                      }
                      <p id="description">{desc}</p>
                      <p id="description">
                        <span style={{ fontWeight: "bold" }}>Ingredients:</span>{" "}
                        {contents.ingredients}
                      </p>
                    </div>
                    <div className="product-footer">
                      <div className="product-count style d-flex flex-column flex-sm-row mt-30 mb-30">
                        {contents.new? (
                          <div>
                          {added ? (
                            <div className="flex">
                              <button
                                className="btn mt-5 mt-sm-0 rounded-5"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                disabled
                              >
                                <span className="mr-2">
                                  <i className="fa fa-check" />
                                </span>
                                Added to Cart
                              </button>
                              <Link
                                className="btn mt-5 mt-sm-0 rounded-5"
                                style={{
                                  color: "green",
                                  marginLeft: "10px",
                                }}
                                to="/cart"
                              >
                                Open Cart
                              </Link>
                            </div>
                          ) : (
                            <button
                              className="btn mt-5 mt-sm-0 rounded-5"
                              style={{ backgroundColor: "red", color: "white" }}
                              onClick={addToBasket}
                            >
                              <span className="mr-2">
                                <i className="fa fa-cart-plus" />
                              </span>
                              Add to cart
                            </button>
                          )}
                        </div>
                        ): (
                          <button
                            className="btn mt-5 mt-sm-0 rounded-5"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                            }}
                            disabled
                          >
                            Add to Cart
                          </button>
                        )}
                      </div>
                      {
                        contents.new?(
                          <div className="addto-whish-list">
                            <button onClick={addToWishlist}>
                              <i className="icon-heart" /> Add to wishlist
                            </button>
                          </div>
                        ) : null
                      }
                      <div className="pro-social-links mt-10">
                        <ul className="d-flex align-items-center">
                          <li className="share">Share</li>
                          <li>
                            <a href="https://www.facebook.com/refuellabz">
                              <i className="ion-social-facebook" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/Refuellabz">
                              <i className="ion-social-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/refuel_labz/">
                              <i className="ion-social-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.amazon.in/s?me=AYQYUW16VWCXY&marketplaceID=A21TJRUUN4KGV">
                              <i className="fab fa-amazon" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* product-single end */}
          {/* product tab start */}
          {/* <div className="product-tab theme3 bg-white pt-60 pb-80">
            <div className="container">
              <div className="product-tab-nav">
                <div className="row align-items-center">
                  <div className="col-12">
                    <nav className="product-tab-menu single-product">
                      <ul
                        className="nav nav-pills justify-content-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Description
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Product Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="pills-contact-tab"
                            data-toggle="pill"
                            href="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            Reviews
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="pills-tabContent">
                    
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="single-product-desc">
                        <ul>
                          <li>
                            Block out the haters with the fresh adidas®
                            Originals Kaval Windbreaker Jacket.
                          </li>
                          <li>Part of the Kaval Collection.</li>
                          <li>
                            Regular fit is eased, but not sloppy, and perfect
                            for any activity.
                          </li>
                          <li>
                            Plain-woven jacket specifically constructed for
                            freedom of movement.
                          </li>
                          <li>
                            Soft fleece lining delivers lightweight warmth.
                          </li>
                          <li>Attached drawstring hood.</li>
                          <li>Full-length zip closure.</li>
                          <li>Long sleeves with stretch cuffs.</li>
                          <li>Side hand pockets.</li>
                          <li>Brand graphics at left chest and back.</li>
                          <li>Straight hem.</li>
                          <li>
                            Shell: 100% nylon;
                            <br />
                            Lining: 100% polyester.
                          </li>
                          <li>Machine wash, tumble dry.</li>
                          <li>Imported.</li>
                          <li>
                            <div>
                              Product measurements were taken using size MD.
                              Please note that measurements may vary by size.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="single-product-desc">
                        <div className="studio-thumb">
                          <a href="#">
                            <img
                              className="mb-30"
                              src="assets/img/stodio.jpg"
                              alt="studio-thumb"
                            />
                          </a>
                          <h6 className="mb-2">
                            Reference <small>demo_1</small>{" "}
                          </h6>
                          <h6>
                            In stock <small>300 Items</small>{" "}
                          </h6>
                          <h3>Data sheet</h3>
                        </div>
                        <div className="product-features">
                          <ul>
                            <li>
                              <span>Compositions</span>
                            </li>
                            <li>
                              <span>Cotton</span>
                            </li>
                            <li>
                              <span>Paper Type</span>
                            </li>
                            <li>
                              <span>Doted</span>
                            </li>
                            <li>
                              <span>Color</span>
                            </li>
                            <li>
                              <span>Black</span>
                            </li>
                            <li>
                              <span>Size</span>
                            </li>
                            <li>
                              <span>L</span>
                            </li>
                            <li>
                              <span>Frame Size</span>
                            </li>
                            <li>
                              <span>40x60cm</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="single-product-desc">
                        <div className="grade-content">
                          <span className="grade">Grade </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <h6 className="sub-title">test</h6>
                          <p>23/09/2020</p>
                          <h4 className="title">test</h4>
                          <p>test</p>
                        </div>
                        <hr className="hr" />
                        <div className="grade-content">
                          <span className="grade">Grade </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <h6 className="sub-title">Hastheme</h6>
                          <p>23/09/2020</p>
                          <h4 className="title">demo</h4>
                          <p>ok !</p>
                          <a
                            href="#"
                            className="btn theme-btn--dark3 theme-btn--dark3-sm btn--sm rounded-5 mt-15"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                          >
                            Write your review !
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* product tab end */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProductSingle;

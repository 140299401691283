import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import { db } from "../firebase";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HeaderScroll from "../Components/HeaderScroll";

function Contact() {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const submitMessage = (event) => {
      event.preventDefault();
      db.collection("messages")
        .add({
          name: event.target.name.value,
          number: event.target.number.value,
          email: event.target.email.value,
          message: event.target.message.value,
          time: new Date(),
        })
        .then((result) => {
          console.log("Message Sent");
          handleShow();
          // alert(`Hi ${name}! Your Message has been sent successfully. Our team will contact you soon :-)`);
        })
        .catch((err) => console.log(err));
      
          document.getElementById("contact-form").reset();
    }

    const [showN, setShowN] = useState(true);
    const controlNavbar = () => {
      if (window.scrollY > 20) {
        setShowN(false);
      } else {
        setShowN(true);
      }
    };
    useEffect(() => {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }, []);

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mb-8">
            Hi! Your Message has been sent successfully. Our team will contact
            you soon :-)
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showN ? <Header /> : <HeaderScroll />}
      <nav className="breadcrumb-section theme1 bg-lighten2 pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center mb-15">
                <h2 className="title text-dark text-capitalize">Contact Us</h2>
              </div>
            </div>
            <div className="col-12">
              <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="index.html">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ol>
            </div>
          </div>
        </div>
      </nav>
      {/* breadcrumb-section end */}
      {/* product tab start */}
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7018.7897014836935!2d76.93219187689971!3d28.407340266997206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3df143b0d3ab%3A0x2d1aea2870b92b65!2sSector%2090%2C%20Gurugram%2C%20Haryana%20122505!5e0!3m2!1sen!2sin!4v1642133999312!5m2!1sen!2sin" />
      </div>
      <section className="contact-section pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 mb-30">
              {/*  contact page side content  */}
              <div className="contact-page-side-content">
                <h3 className="contact-page-title">Contact Us</h3>
                {/*  single contact block  */}
                <div className="single-contact-block">
                  <h4>
                    <i className="fa fa-fax" /> Address
                  </h4>
                  <p>Plot No. 2, Sector-90, Gurugram, Haryana - 122505</p>
                </div>
                {/*  End of single contact block */}
                {/*  single contact block */}
                <div className="single-contact-block">
                  <h4>
                    <i className="fa fa-phone" /> Phone
                  </h4>
                  <p>
                    <a
                      href="tel:+919306293768"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      Mobile: +91 9306293768
                    </a>
                  </p>
                </div>
                {/* End of single contact block */}
                {/*  single contact block */}
                <div className="single-contact-block">
                  <h4>
                    <i className="fas fa-envelope" /> Email
                  </h4>
                  <p>
                    <a
                      href="mailto:care@refuellabz.com"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      care@refuellabz.com
                    </a>
                  </p>
                </div>
                {/*=======  End of single contact block  =======*/}
              </div>
              {/*=======  End of contact page side content  =======*/}
            </div>
            <div className="col-lg-6 col-12 mb-30">
              {/*  contact form content */}
              <div className="contact-form-content">
                <h3 className="contact-page-title">Contact us</h3>
                <div className="contact-form">
                  <form
                    id="contact-form"
                    name="contactForm"
                    className="contactForm"
                    onSubmit={submitMessage}
                  >
                    <div className="form-group">
                      <label>
                        Your Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="customername"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Your Email <span className="required">*</span>
                      </label>
                      <input type="email" name="email" id="email" required />
                    </div>
                    <div className="form-group">
                      <label>Contact No.</label>
                      <input type="text" name="number" id="number" required />
                    </div>
                    <div className="form-group">
                      <label>Your Message</label>
                      <textarea
                        name="message"
                        className="pb-10"
                        id="message"
                        required
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group mb-0">
                      <button
                        type="submit"
                        className="btn theme-btn--dark1 btn--lg"
                      >
                        submit
                      </button>
                    </div>
                  </form>
                </div>
                <p className="form-messegemt-10" />
              </div>
              {/* End of contact */}
            </div>
          </div>
        </div>
      </section>
      {/* product tab end */}
      <Footer />
    </div>
  );
}

export default Contact;

import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import slide2 from "../assets/img/slide2.jpeg";
import p7 from "../assets/img/p7.jpg";
import p9 from "../assets/img/p9.jpg";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import v10 from "../assets/img/v10.mp4";
import p2 from "../assets/img/p2.jpg";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import HeaderScroll from "../Components/HeaderScroll";
import Footer from "../Components/Footer";
import Product from "../Components/Product";
import ProductNew from "../Components/ProductNew";
import { useStateValue } from "../StateProvider";
import Data from "../Data/products.json";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "red",
    boxShadow: "none",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    // bmBurgerBars: {
    //   background: "#373a47",
    // },
    // bmBurgerBarsHover: {
    //   background: "#a90000",
    // },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 30) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div className="Krishna-Main">
      {showN ? <Header /> : <HeaderScroll />}
      <a href="https://verify.refuellabz.com" style={{backgroundColor: 'black', border:'1px solid white', color:"white", textDecoration:"none", position:"fixed", bottom:15, right:15, padding: 8, borderRadius:10, zIndex:50}}>Verify your product</a>
      <div>
        <div className="offcanvas-overlay" />
        {/* main slider start */}
        <section className="bg-light position-relative">
          <div className="main-slider dots-style theme1">
            <Carousel
              showThumbs={false}
              autoPlay={true}
              interval={4000}
              infiniteLoop={true}
              >
              <div>
                {mobileView ? (
                  <Link to="/collection/proteins">
                    <div>
                      <img src="https://i.ibb.co/D8L5mrW/post-7-mobile.jpg" />
                    </div>
                  </Link>
                ) : (
                  <Link to="/collection/proteins">
                    <div>
                      <img src={p7} />
                    </div>
                  </Link>
                )}
              </div>
              <div>
                {mobileView ? (
                  <Link to="/collection/aminos">
                    <div>
                      <img src="https://i.ibb.co/5Y8Nrvg/post-8-mobile.jpg" />
                    </div>
                  </Link>
                ) : (
                  <Link to="/collection/aminos">
                    <div>
                      <img src={p2} />
                    </div>
                  </Link>
                )}
              </div>
              <div>
                {mobileView ? (
                  <Link to="/shop">
                    <div>
                      <img src="https://i.ibb.co/CVmVzkS/post-9-mobile.jpg" />
                    </div>
                  </Link>
                ) : (
                  <Link to="/shop">
                    <div>
                      <img src={p9} />
                    </div>
                  </Link>
                )}
              </div>
              <div>
                {mobileView ? (
                  <Link to="/shop">
                    <div>
                      <img src="https://i.ibb.co/7JNW5JT/post-6-mobile.jpg" />
                    </div>
                  </Link>
                ) : (
                  <Link to="/shop">
                    <div>
                      <img src={  slide2} />
                    </div>
                  </Link>
                )}
              </div>
            </Carousel>

            {/* slider-item end */}
          </div>
          {/* slick-progress */}
          <div className="slick-progress">
            <span />
          </div>
          {/* slick-progress end*/}
        </section>
        {/* main slider end */}
        {/* common banner  start */}
        <div className="common-banner pt-80 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-30">
                <div className="banner-thumb">
                  <Link
                    to="/shop"
                    className="zoom-in d-block overflow-hidden"
                  >
                    <img src="https://i.ibb.co/wcBmDWp/post-13.jpg" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-30">
                <div className="banner-thumb">
                  <Link
                    to="/collection/proteins"
                    className="zoom-in d-block overflow-hidden"
                  >
                    <img
                      src="https://i.ibb.co/4jfF1Qb/post-11.jpg"
                      alt="banner-thumb-naile"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-30">
                <div className="banner-thumb">
                  <Link
                    to="/collection/aminos"
                    className="zoom-in d-block overflow-hidden"
                  >
                    <img
                      src="https://i.ibb.co/cYNbfbF/post-12.jpg"
                      alt="banner-thumb-naile"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* common banner  end */}
        {/* product tab start */}
        <section className="product-tab bg-white pt-50 ">
          <div className="container">
            <div className="product-tab-nav mb-35">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="section-title text-center mb-30">
                    <h2 className="title text-dark text-capitalize">
                      Our products
                    </h2>
                    <p className="text mt-10">
                      Checkout our exciting product range!
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <nav className="product-tab-menu theme1">
                    <Paper className={classes.root}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        // indicatorColor="primary"
                        TabIndicatorProps={{ style: { background: "red" } }}
                        centered
                      >
                        <Tab label="FEATURED" />
                        <Tab label="BEST SELLERS" />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        <div className="container">
                          <div className="row">
                            {Data.slice(1, 2).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(0, 1).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(2, 3).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(12, 13).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div className="container">
                          <div className="row">
                            {Data.slice(8, 9).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(2, 3).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(6, 7).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}

                            {Data.slice(0, 1).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </TabPanel>
                    </Paper>
                  </nav>
                </div>
              </div>
            </div>
            {/* product-tab-nav end */}
          </div>
        </section>
        {/* product tab end */}
        {/* common banner  start */}
        <div className="common-banner bg-white pb-50">
          <div className="container">
            <div className="row" id="collection">
              <div className="col-12">
                <div className="section-title text-center mb-30">
                  <h2 className="title text-dark text-capitalize">
                    Shop by Category
                  </h2>
                  {/* <p class="text mt-10">Add our products to weekly line up</p> */}
                </div>
              </div>
              <div className="col-6 col-md-3" style={{display:"flex"}}>
              <Link to="/collection/proteins" className="mx-auto">
                <img
                  src="https://i.ibb.co/yBCw9xD/image-2.png"
                  style={{width:160}}
                  alt="banner-thumb-naile"
                />
                </Link>
              </div>
              <div className="col-6 col-md-3" style={{display:"flex"}}>
                <Link to="/collection/aminos" className="mx-auto">
                  <img
                    src="https://i.ibb.co/ftkxjKt/image-3.png"
                    style={{width:160}}
                    alt="banner-thumb-naile"
                    
                  />
                </Link>
              </div>
              <div className="col-6 col-md-3" style={{display:"flex"}}>
              <Link to="/collection/pre-workouts" className="mx-auto">
                <img
                  src="https://i.ibb.co/vVMx17V/l2.png"
                  style={{width:160}}
                  alt="banner-thumb-naile"
                />
                </Link>
              </div>
              <div className="col-6 col-md-3 banner-thumb" style={{display:"flex"}}>
              <Link to="/collection/gainers" className="mx-auto">
                <img
                  src="https://i.ibb.co/LNpkP9Y/l4.png"
                  style={{width:160}}
                  alt="banner-thumb-naile"
                  className="mx-auto zoom-in d-block overflow-hidden position-relative"
                />
                </Link>
              </div>
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="banner-thumb">
                  <div className="zoom-in d-block overflow-hidden position-relative">
                    <Link to="/collection/aminos">
                      <img
                        src="https://i.ibb.co/hH34Lt4/protein-jar-mockup-01-1.png"
                        alt="banner-thumb-naile"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mb-30">
                <div className="banner-thumb">
                  <div className="zoom-in d-block overflow-hidden position-relative mb-30">
                    <Link to="/collection/proteins">
                      <img
                        src="https://i.ibb.co/ySs3dCd/mockup-213.png"
                        alt="banner-thumb-naile"
                      />
                    </Link>
                  </div>
                  <div className="zoom-in d-block overflow-hidden position-relative">
                    <Link to="/collection/pre-workouts">
                      <img
                        src="https://i.ibb.co/bLnTWfH/mockup-209-3.png"
                        alt="banner-thumb-naile"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="banner-thumb">
                  <div className="zoom-in d-block overflow-hidden position-relative">
                    <Link to="/collection/gainers">
                      <img
                        src="https://i.ibb.co/D8yhDCZ/protein-jar-mockup-01-2.png"
                        alt="banner-thumb-naile"
                      />
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* common banner  end */}
        {/* product tab repetation start */}
        <section className="bg-white theme1 pt-50 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5 col-xl-4 mb-5 mb-lg-0">
                <div className="card product-card no-shadow theme-border">
                  <div className="product-ctry-init slick-nav-sync">
                    <div className="slider-item">
                      <div className="card-body pt-4 px-4 pb-5 position-relative">
                        <div className="hot-deal d-flex align-items-center justify-content-between">
                          <h3 className="title text-dark text-capitalize">
                            hot deals
                          </h3>
                          <span className="badge badge-success position-static cb6">
                            -20%
                          </span>
                        </div>
                        {/* countdown-sync-init */}
                        <div className="countdown-sync-init">
                          <div className="countdown-item">
                            <div className="product-thumb">
                              <img
                                src="https://i.ibb.co/sFjQ81h/image.jpg"
                                alt="product-thumb"
                              />
                            </div>
                          </div>
                          {/* single-product end */}
                        </div>
                        {/* countdown-sync-nav */}

                        <div className="product-desc text-center p-0">
                          <h3 className="title">
                            <Link to="/products/14">
                              Platinum Whey Protein - Kulfi Flavour, 2kg
                            </Link>
                          </h3>
                          <div className="star-rating">
                            <span className="ion-ios-star" />
                            <span className="ion-ios-star" />
                            <span className="ion-ios-star" />
                            <span className="ion-ios-star" />
                            <span className="ion-ios-star" />
                          </div>
                          <div className="text-center position-relative">
                            <h6 className="product-price">
                              <del className="del">₹ 6999</del>
                              <span className="onsale">₹ 5599</span>
                            </h6>
                            {/* <button
                              className="pro-btn pro-btn-right"
                              data-toggle="modal"
                              data-target="#add-to-cart"
                            >
                              <i className="icon-basket" />
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* slider-item End */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-8">
                {/* section-title start */}
                <div className="section-title text-center mb-30">
                  <h2 className="title text-dark text-capitalize">
                    New Arrivals
                  </h2>
                  <p className="text mt-10">
                    Following are some of the newly launched products.
                  </p>
                </div>
                {/* section-title end */}
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="product-slider-init theme1 slick-nav">
                    <div className="container">
                      <div className="row">
                        {!mobileView ? (
                          <div style={{ display: "flex" }}>
                            {Data.slice(1, 2).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(2, 3).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(3, 4).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                            {Data.slice(12, 13).map((product) => {
                              return (
                                product.new ? (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <ProductNew
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                ): (
                                  <div className="product-list mb-30 col-lg-3 p-10">
                                    <Product
                                      id={product.id}
                                      imgUrl={product.image[0]}
                                      title={product.name}
                                      originalPrice={product.price}
                                      discountedPrice={
                                        product.price -
                                        ((product.price / 100) * 20).toFixed(0)
                                      }
                                      productUrl={`/products/${product.id}`}
                                    />
                                  </div>
                                )
                              );
                            })}
                          </div>
                        ) : null}
                        {Data.slice(8, 9).map((product) => {
                          return (
                            product.new ? (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <ProductNew
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            ): (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <Product
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            )
                          );
                        })}
                        {Data.slice(12, 13).map((product) => {
                          return (
                            product.new ? (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <ProductNew
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            ): (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <Product
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            )
                          );
                        })}
                        {Data.slice(6, 7).map((product) => {
                          return (
                            product.new ? (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <ProductNew
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            ): (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <Product
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            )
                          );
                        })}

                        {Data.slice(13, 14).map((product) => {
                          return (
                            product.new ? (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <ProductNew
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            ): (
                              <div className="product-list mb-30 col-lg-3 p-10">
                                <Product
                                  id={product.id}
                                  imgUrl={product.image[0]}
                                  title={product.name}
                                  originalPrice={product.price}
                                  discountedPrice={
                                    product.price -
                                    ((product.price / 100) * 20).toFixed(0)
                                  }
                                  productUrl={`/products/${product.id}`}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    {/* slider-item end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product tab repetation end */}
        <section className="static-media-section pb-80 bg-white">
        <div className="container">
          <div class="row">
              <div className="col-12">
                <div className="section-title text-center mb-30">
                  <h2 className="title text-dark text-capitalize">
                    Founder's Message
                  </h2>
                </div>
              </div>
              <div className="col-md-1 col-sm py-3"></div>
            <div className="col-sm-6 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
                <p>
                  I am honored to welcome you to Refuel Labz, a company that is dedicated to helping you optimize your health and wellness through the power of natural nutrients.
                  As the founder of this company, I understand that the modern diet often falls short in providing the necessary nutrients that our bodies need to function at their best. That is why we have developed a line of supplements that are designed to support your overall health and well-being.
                  <br />
                  Our supplements are made using only the highest quality ingredients, sourced from the most reputable suppliers in the industry. We believe in the power of nature and strive to create products that are as close to their natural form as possible, without any harmful additives or preservatives.
                  <br />
                  Thank you for choosing us as your trusted partner in your journey towards better health and wellness. We are excited to be a part of your journey and look forward to helping you achieve your goals.
                  <br /><br />
                  <b>Dr. Saurabb Naarang</b>
                  <br />
                  Founder, Refuel Labz
                </p>
              </div>
            </div>
            <div className="col-sm-4 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
              <Link to='/about'>
                <img
                  className="align-self-center mb-2 mb-sm-0 mr-auto zoom"
                  src="https://i.ibb.co/3sYHMf4/01-A0009-copy-1.jpg"
                  alt="fonder-img"
                  style={{boxShadow:"5px 20px 37px rgb(0 0 0 / 30%)", borderRadius:20}}
                />
                </Link>
              </div>
            </div>
            <div className="col-md-1 col-sm py-3"></div>
          </div>
        </div>
        
        </section>
        <section className="static-media-section pb-80 bg-white">
          <div className="container">
            <div className="static-media-wrap rounded-5" style={{backgroundImage:"linear-gradient(#3c096c, #230045)"}}>
                      <h2 className="title text-capitalize text-white text-center" style={{marginBottom:10}}>
                        Stay Assured
                      </h2>
              <div className="row">

                <div className="col-lg-4 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src="https://i.ibb.co/bswNrz1/image.png"
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Ensuring complete quality control
                      </h4>
                      <p className="text text-white">
                        In-house manufacturing & research facility
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src="https://i.ibb.co/xmD0x3G/image1.png"
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Transparent Ingredient Sourcing
                      </h4>
                      <p className="text text-white">
                        Clinically proven ingredient from reputed sources
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src="https://i.ibb.co/sR97kWc/image2.png"
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Sustainable manufacturing
                      </h4>
                      <p className="text text-white">
                        We make sure to add to the environment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="static-media-section pb-80 bg-white">
        <div className="container">
          <div class="row">
              <div className="col-12">
                <div className="section-title text-center mb-30">
                  <h2 className="title text-dark text-capitalize">
                    @refuel_labz
                  </h2>
                </div>
              </div>
            <div className="col-md-4 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
                <a href="https://www.instagram.com/refuel_labz">
                  <img
                    className="align-self-center mb-2 mb-sm-0 zoom"
                    src="https://i.ibb.co/HKXThhN/Whats-App-Image-2023-04-03-at-1-45-31-PM.jpg"
                    alt="icon"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
              <a href="https://www.instagram.com/refuel_labz">
                <img
                  className="align-self-center mb-2 mb-sm-0 mr-auto zoom"
                  src="https://i.ibb.co/4srjg4X/Whats-App-Image-2023-04-03-at-1-45-32-PM-1.jpg"
                  alt="icon"
                />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
              <a href="https://www.instagram.com/refuel_labz">
                <img
                  className="align-self-center mb-2 mb-sm-0 mr-auto zoom"
                  src="https://i.ibb.co/3NFysfD/Whats-App-Image-2023-04-03-at-1-45-32-PM.jpg"
                  alt="icon"
                />
                </a>
              </div>
            </div>
            {/* <div className="col-md-3 col-sm py-3">
              <div className="d-flex static-media2 flex-column flex-sm-row">
              <a href="https://www.instagram.com/refuel_labz">
                  <video src={v10} className="col-md-3 col-sm"  controls="controls" autoplay="true" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
        </section>
        {/* staic media start */}
        <section className="static-media-section pb-80 bg-white">
          <div className="container">
            <div className="static-media-wrap theme-bg rounded-5">
              <div className="row">
                <div className="col-lg-3 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src={freeship}
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Free Shipping
                      </h4>
                      <p className="text text-white">
                        On all orders without any minimum order limit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src={box}
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Best of the Range Quality
                      </h4>
                      <p className="text text-white">
                        Perfect quality of products with delicious flavours
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src={secure}
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        100% Payment Secure
                      </h4>
                      <p className="text text-white">
                        Your payment are safe with us.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 py-3">
                  <div className="d-flex static-media2 flex-column flex-sm-row">
                    <img
                      className="align-self-center mb-2 mb-sm-0 mr-auto mr-sm-3"
                      src={customer}
                      alt="icon"
                    />
                    <div className="media-body">
                      <h4 className="title text-capitalize text-white">
                        Support 24/7
                      </h4>
                      <p className="text text-white">
                        Contact us 24 hours a day
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* staic media end */}
        {/* blog-section end */}
        <div className="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7018.7897014836935!2d76.93219187689971!3d28.407340266997206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3df143b0d3ab%3A0x2d1aea2870b92b65!2sSector%2090%2C%20Gurugram%2C%20Haryana%20122505!5e0!3m2!1sen!2sin!4v1642133999312!5m2!1sen!2sin" />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import New from "../assets/img/product/New.jpg";
import CurrencyFormat from "react-currency-format";
import Footer from "../Components/Footer";
import { useStateValue } from "../StateProvider";
import HeaderScroll from "../Components/HeaderScroll";
import Data from "../Data/products.json";
import Product from "../Components/Product";
import ProductNew from "../Components/ProductNew";

function Cart() {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const [{ basket }, dispatch] = useStateValue();

  var totalCartPrice = 0;
  for (var i = 0; i < basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice;
  }

  const removeFromBasket = (id) => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  

  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav className="breadcrumb-section theme1 bg-lighten2 pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">Shop</h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Shop
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <div className="product-tab bg-white pt-80 pb-50">
          <div className="container">
            <div class="grid-nav-wraper bg-lighten2 mb-30">
              <div class="row align-items-center">
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <nav class="shop-grid-nav">
                    <ul
                      class="nav nav-pills align-items-center"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li>
                        {" "}
                        <span class="total-products text-capitalize">
                          3 products listed.
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* <div class="col-12 col-md-6 position-relative">
                  <div class="shop-grid-button d-flex align-items-center">
                    <span class="sort-by">Sort by:</span>
                    <button
                      class="btn-dropdown rounded d-flex justify-content-between"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Relevance <span class="ion-android-arrow-dropdown"></span>
                    </button>
                    <div
                      class="dropdown-menu shop-grid-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        {" "}
                        Price, low to high
                      </a>
                      <a class="dropdown-item" href="#">
                        {" "}
                        Price, high to low
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-30">
                {/* product-tab-nav end */}
                <div className="tab-content" id="pills-tabContent">
                  {/* first tab-pane */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row grid-view theme1">
                      {Data.map((product) => {
                        return (
                              product.new ? (
                                <div className="product-list mb-30 col-lg-3 p-10">
                                  <ProductNew
                                    id={product.id}
                                    imgUrl={product.image[0]}
                                    title={product.name}
                                    originalPrice={product.price}
                                    discountedPrice={
                                      product.price -
                                      ((product.price / 100) * 20).toFixed(0)
                                    }
                                    productUrl={`/products/${product.id}`}
                                  />
                                </div>
                              ): null
                        );
                      })}
                    </div>
                  </div>
                  {/* second tab-pane */}
                </div>
                <div className="row">
                  <div className="col-12">
                    <nav className="pagination-section mt-30">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <ul className="pagination justify-content-center">
                            <li className="page-item active">
                              <Link className="page-link" to="/shop">1</Link>
                            </li>
                            <li className="page-item">
                              <Link className="page-link" to="/shop/2">2</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Cart;

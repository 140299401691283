import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import New from "../assets/img/product/New.jpg";
import CurrencyFormat from "react-currency-format";
import Footer from "../Components/Footer";
import { useStateValue } from "../StateProvider";
import HeaderScroll from "../Components/HeaderScroll";
import { db, auth } from "../firebase";
import { useNavigate } from "react-router-dom";

function Orders() {
  const mql = window.matchMedia("(max-width: 600px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let mobileView = mql.matches;

  const [{ basket }, dispatch] = useStateValue();

  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setEmail(authUser.email);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    db.collection("orders").onSnapshot((snapshot) =>
      setOrders(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);

  var filtered = [];
  for (var i = 0; i < orders.length; i++) {
    if (orders[i].authenticatedEmail == email) {
      filtered.push(orders[i]);
    }
  }

  //   var totalCartPrice = 0;
  //   for (var i = 0; i < orders[i].basket.length; i++) {
  //     totalCartPrice += orders[i].basket[i].discountedPrice;
  //   }

  const removeFromBasket = (id) => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav className="breadcrumb-section theme1 bg-lighten2 pt-110 pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">My Orders</h2>
                </div>
              </div>
              <div className="col-12">
                <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Orders
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <section className="whish-list-section theme1 pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="title mb-30 pb-25 text-capitalize">
                  Your Orders
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center" scope="col">
                          Product Image
                        </th>
                        <th className="text-center" scope="col">
                          Product Name
                        </th>
                        <th className="text-center" scope="col">
                          Total Order Price
                        </th>
                        <th className="text-center" scope="col">
                          Status
                        </th>
                        <th className="text-center" scope="col">
                          Order Id
                        </th>
                      </tr>
                    </thead>
                    {filtered.length >= 1 ? (
                      <tbody>
                        {filtered.map((product) => (
                          <tr>
                            <td className="text-center" scope="row">
                              <img src={product.orders[0].imgUrl} alt="img" />
                              {product.orders.length > 1 ? (
                                <span style={{ fontWeight: "bold" }}>
                                  &amp; {product.orders.length - 1} more items
                                </span>
                              ) : null}
                            </td>
                            <td className="text-center">
                              <span className="whish-title">
                                {product.orders[0].title}{" "}
                                {product.orders.length > 1 ? (
                                  <span style={{ fontWeight: "bold" }}>
                                    &amp; {product.orders.length - 1} more items
                                  </span>
                                ) : null}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="whish-list-price">
                                ₹ {product.orders[0].discountedPrice}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="whish-list-price">
                                <span style={{ fontWeight: "bold" }}>
                                  {product.status}
                                </span>
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="whish-list-price">
                                {product.paymentId}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : null}
                  </table>
                  {filtered.length == 0 ? (
                    <p style={{ fontSize: "25px", textAlign: "center" }}>
                      Order List is Empty :-(
                    </p>
                  ) : null}
                </div>
                <Link
                  className="btn"
                  style={{
                    backgroundColor: "#d9ffd8",
                    color: "white",
                    padding: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    fontSize: "19px",
                    color: "#56c644",
                    fontWeight: "bold",
                    borderRadius: "15px",
                    float:"right", marginTop: "10px"
                  }}
                  to="/my-account"
                >
                  <i className="fa fa-user pr-3" />
                  Account Home
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* product tab end */}
        <Footer />
      </div>
    </div>
  );
}

export default Orders;

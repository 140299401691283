import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { db } from "../firebase";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HeaderScroll from "../Components/HeaderScroll";
import ds from "../assets/img/blog-post/ds.jpg"

function About() {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitMessage = (event) => {
    event.preventDefault();
    db.collection("messages")
      .add({
        name: event.target.name.value,
        number: event.target.number.value,
        email: event.target.email.value,
        message: event.target.message.value,
        time: new Date(),
      })
      .then((result) => {
        console.log("Message Sent");
        handleShow();
        // alert(`Hi ${name}! Your Message has been sent successfully. Our team will contact you soon :-)`);
      })
      .catch((err) => console.log(err));

    document.getElementById("contact-form").reset();
  };

  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <nav className="breadcrumb-section theme1 bg-lighten2 pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center mb-15">
                <h2 className="title text-dark text-capitalize">About Us</h2>
              </div>
            </div>
            <div className="col-12">
              <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About
                </li>
              </ol>
            </div>
          </div>
        </div>
      </nav>
      {/* breadcrumb-section end */}
      {/* product tab start */}

      <section className="about-section pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-30">
              <div className="about-content">
                <h2 className="title mb-20">Welcome To Refuel Labz</h2>
                <p
                  className="mb-20"
                  style={{ color: "rgb(107 114 128)", lineHeight: "1.7" }}
                >
                  Refuel Labz is a sports nutrition and Supplement brand
                  delivering a range of world class nutritional supplements and
                  wellness products. The brain behind this whole concept is Dr
                  Saurabb Naarang, an accomplished and awarded Medical Doctor
                  and Fitness Professional, who has deep expertise and interest
                  in fitness &amp; Supplement science. With his years of
                  research and an heartfelt urge to provide the Indian consumer
                  a genuine solution for their nutritional needs and well being
                  of youth, he has launched Refuel Labz sports nutritional
                  supplements.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Refuel Labz offers a great range of high quality products-
                  Proteins, Aminos , Mass gainers, pre workout, and many other
                  health supplements.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="about-left-image mb-30">
                <img
                  src="https://i.ibb.co/44KJdmv/New-Project.png"
                  alt="img"
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-30">
              <div className="about-info">
                <h4 className="title mb-20">Perfect Raw Materials</h4>
                <p style={{ color: "rgb(107 114 128)", lineHeight: "1.7" }}>
                  Refuel labz nutrition products are manufactured with premium
                  quality raw materials imported from USA. The raw materials
                  then go through quality test before they are marked fit for
                  the next step to ensure 100% authenticity This multi level
                  process eliminates the harmful substances, and deliver premium
                  nutrition and Supplements to sports enthusiasts and atheletes.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-30">
              <div className="about-info">
                <h4 className="title mb-20">100% Genuine</h4>
                <p style={{ color: "rgb(107 114 128)", lineHeight: "1.7" }}>
                  Our manufacturing facility is one of the very few in India
                  which is US FDA (Food & Drug Administration) registered. Our
                  plant is GMP (Good Manufacturing Practice) compliant, ISO
                  9001:2015 certified and HACCP (Hazards Analysis and Critical
                  Control Point) accredited. All our products are free from
                  banned substances as per WADA/NADA as per International
                  product standards.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-30">
              <div className="about-info">
                <h4 className="title mb-20">Perfect Procedures</h4>
                <p style={{ color: "rgb(107 114 128)", lineHeight: "1.7" }}>
                  Our production facility gives birth to the best and purest
                  nutritional supplements for optimum results. Our
                  state-of-the-art production facilities and sophisticated
                  testing measures have resulted in ensuring that each Refuel
                  Labz product rings true to the promise of Purity, Potency and
                  Perfection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* product tab end */}
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7018.7897014836935!2d76.93219187689971!3d28.407340266997206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3df143b0d3ab%3A0x2d1aea2870b92b65!2sSector%2090%2C%20Gurugram%2C%20Haryana%20122505!5e0!3m2!1sen!2sin!4v1642133999312!5m2!1sen!2sin" />
      </div>
      <Footer />
    </div>
  );
}

export default About;

import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderScroll from "../Components/HeaderScroll";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Contact() {
  const [showN, setShowN] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  return (
    <div>
      {showN ? <Header /> : <HeaderScroll />}
      <div class="wrapper">
        <div class="page">
          <div class="container">
            <div class="row">
              <div class="col-md-12 mt-20 mb-20">
                <div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n",
                    }}
                  />
                  <div data-custom-class="body">
                    <div>
                      <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "center", lineHeight: "115%" }}
                      >
                        <a name="_2cipo4yr3w5d" />
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "150%" }}
                        >
                          <strong>
                            <span style={{ fontSize: 26 }}>
                              <span data-custom-class="title">
                                RETURN POLICY
                              </span>
                            </span>
                          </strong>
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "150%" }}
                        >
                          <br />
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "150%" }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <strong>
                                <span data-custom-class="subtitle">
                                  Last updated{" "}
                                  <bdt className="question">
                                    January 25, 2022
                                  </bdt>
                                </span>
                              </strong>
                            </span>
                          </span>
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "150%" }}
                        >
                          <br />
                        </div>
                        <div
                          align="center"
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "150%" }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <br />
                            <a name="_2cipo4yr3w5d" />
                          </span>
                        </div>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          If you are not completely
                          satisfied with your purchase for any reason, you may
                          return it to us for{" "}
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="03b751bb-5eee-5230-df87-d0707fec3124"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="policy_type"
                                data-type="statement"
                              />
                              <bdt data-type="body">an exchange only</bdt>
                            </bdt>
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="policy_type"
                                data-type="statement"
                              />
                            </bdt>
                            . Please see below for more information on our
                            return policy.
                          </bdt>
                        </span>
                      </div>
                    </div>
                    <div style={{ lineHeight: "1.1" }}>
                      <br />
                    </div>
                    <div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <a name="_iwimutmowezb" />
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            RETURNS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div style={{ lineHeight: "1.1" }}>
                      <br />
                    </div>
                    <div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          All returns must be postmarked within{" "}
                          <bdt
                            className="block-container question question-in-editor"
                            data-id="10b33c27-be1f-aeda-7ea3-7c1f52ee6130"
                            data-type="question"
                          >
                            seven (7)
                          </bdt>{" "}
                          days of the purchase date. All returned items must be
                          in new and unused condition, with all original tags
                          and labels attached.
                        </span>
                      </div>
                    </div>
                    <div style={{ lineHeight: "1.1" }}>
                      <br />
                    </div>
                    <div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <a name="_16t1v96tankw" />
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            RETURN PROCESS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div style={{ lineHeight: "1.1" }}>
                      <br />
                    </div>
                    <div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          To return an item,{" "}
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="51c4b423-789c-79e6-4161-743acb653a2d"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="authorization_option"
                                data-type="statement"
                              />
                              <bdt data-type="body">
                                please email customer service at{" "}
                                <bdt
                                  className="block-container question question-in-editor"
                                  data-id="0b78538e-7550-6c62-669a-d2eb2ed0eafb"
                                  data-type="question"
                                >
                                  care@refuellabz.com
                                </bdt>{" "}
                                to obtain a Return Merchandise Authorization
                                (RMA) number. After receiving a RMA
                                number,&nbsp;
                              </bdt>
                            </bdt>
                            <bdt
                              className="statement-end-if-in-editor"
                              data-type="close"
                            />
                          </bdt>
                          place the item securely in its original packaging
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="903ce2af-7990-07ea-2615-36e36315d483"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="return_inlcude_option"
                                data-type="statement"
                              />
                              <bdt data-type="body">,&nbsp;</bdt>
                            </bdt>
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="null"
                                data-type="statement"
                              />
                            </bdt>
                            and mail your return to the following address:
                          </bdt>
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <br />
                          </span>
                        </span>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "115%" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="49265208-ebd3-4d63-5ecb-9fcda064a7d5"
                              data-type="question"
                            >
                              Refuel Labz
                            </bdt>
                            <br />
                          </span>
                        </span>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Attn: Returns
                          </span>
                        </span>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="2e485380-f516-a019-540b-f82bd718b0df"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="authorization_option"
                                  data-type="statement"
                                />
                                <bdt data-type="body">RMA #</bdt>
                              </bdt>
                              <bdt
                                className="statement-end-if-in-editor"
                                data-type="close"
                              />
                            </bdt>
                          </span>
                        </span>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="22673e85-8c82-1948-b0e9-b16dd7c6f007"
                              data-type="question"
                            >
                              Plot No. 2, Sector-90
                            </bdt>
                          </span>
                        </span>{" "}
                        <bdt className="block-component" />
                        &nbsp;
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="765d45c0-0386-b367-b58a-832b154c7ee8"
                              data-type="question"
                            >
                              Gurgaon
                            </bdt>
                            , <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="question">Gurgaon</bdt>
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="statement-end-if-in-editor" />{" "}
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="85b0476b-4b2d-4b3d-060f-fc67c287cbe7"
                              data-type="question"
                            >
                              122005
                            </bdt>{" "}
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            &nbsp;
                          </span>
                        </span>
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt className="question">India</bdt>{" "}
                            <bdt className="statement-end-if-in-editor" />{" "}
                            <bdt className="statement-end-if-in-editor" />{" "}
                            &nbsp;
                          </span>
                        </span>
                      </div>
                    </div>
                    <div style={{ lineHeight: "1.1" }}>
                      <br />
                    </div>
                    <div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="b7518b5d-84c0-c06e-ad97-c7421eb67b0b"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="shipping_fee_option"
                                data-type="statement"
                              />
                            </bdt>
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="d3f0beb2-1468-a072-da09-6936c6e877e2"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="shipping_fee_option"
                                  data-type="statement"
                                />
                              </bdt>
                              <bdt
                                className="block-container if"
                                data-type="if"
                                id="48a0f62a-d42f-f443-061d-cbbf93b49154"
                              >
                                <bdt data-type="conditional-block">
                                  <bdt
                                    className="block-component"
                                    data-record-question-key="shipping_fee_option"
                                    data-type="statement"
                                  />
                                  <bdt data-type="body">
                                    Return shipping charges will be paid or
                                    reimbursed by us.&nbsp;
                                  </bdt>
                                </bdt>
                                <bdt
                                  className="statement-end-if-in-editor"
                                  data-type="close"
                                />
                              </bdt>
                              <bdt
                                className="block-container if"
                                data-type="if"
                                id="ac6ab4e0-da32-a003-8324-04d8b43cb50c"
                              >
                                <bdt data-type="conditional-block">
                                  <bdt
                                    className="block-component"
                                    data-record-question-key="shipping_fee_option"
                                    data-type="statement"
                                  />
                                </bdt>
                              </bdt>
                            </bdt>
                          </bdt>
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <a name="_qxq7t4ufn5pr" />
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            REFUNDS
                          </span>
                        </strong>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <a name="_kcap2nw8xg2p" />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          After receiving your return and inspecting the
                          condition of your item, we will process your{" "}
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="4c11860e-4346-687b-5cb3-3727f319e194"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="policy_type"
                                data-type="statement"
                              />
                              <bdt data-type="body">exchange</bdt>
                            </bdt>
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="null"
                                data-type="statement"
                              />
                            </bdt>
                            . Please allow at least{" "}
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="ab10b1ab-f4a1-256f-29ae-65257d891371"
                              data-type="question"
                            >
                              seven (7)
                            </bdt>{" "}
                            days from the receipt of your item to process your{" "}
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="4c11860e-4346-687b-5cb3-3727f319e194"
                              style={{ fontSize: "14.6667px" }}
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="policy_type"
                                  data-type="statement"
                                />
                                <bdt data-type="body">exchange</bdt>
                              </bdt>
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="null"
                                  data-type="statement"
                                />
                              </bdt>
                              .
                              <bdt
                                className="block-container if"
                                data-type="if"
                                id="16f989a0-873e-9d7c-70f2-1c4b9cc7ecc4"
                              >
                                <bdt data-type="conditional-block">
                                  <bdt
                                    className="block-component"
                                    data-record-question-key="policy_type"
                                    data-type="statement"
                                  />
                                </bdt>
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                  id="b49c01dc-6b19-275b-5996-06e6aeaaf917"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt
                                      className="block-component"
                                      data-record-question-key="customer_notification_option"
                                      data-type="statement"
                                    />
                                  </bdt>
                                </bdt>
                              </bdt>
                            </bdt>
                          </bdt>
                        </span>
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <a name="_gjdgxs" />
                      </div>
                      <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                        <br />
                      </div>
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "115%" }}
                      >
                        <a name="_33ujiidflcnn" />
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            EXCEPTIONS &nbsp;
                          </span>
                        </strong>
                        <span style={{ fontSize: 15 }}>
                          <bdt className="block-component" />{" "}
                          <bdt className="block-component" /> &nbsp;
                        </span>
                      </div>
                      <div
                        data-custom-class="body_text"
                        data-empty="true"
                        style={{ lineHeight: "1.1" }}
                      >
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <br />
                          </span>
                        </span>
                      </div>
                      <div
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            For defective or damaged products, please contact us
                            at the contact details below to arrange a refund or
                            exchange.&nbsp;
                          </span>
                        </span>
                      </div>
                      <div
                        data-custom-class="body_text"
                        data-empty="true"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <bdt className="block-component" /> &nbsp;
                          </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <bdt className="block-component">
                            <span style={{ color: "rgb(89, 89, 89)" }} />
                          </bdt>{" "}
                          &nbsp;
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            &nbsp; <bdt className="block-component" />
                            &nbsp; <bdt className="block-component" />
                            &nbsp;
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.1" }}>
                        <br />
                      </div>
                      <div data-custom-class="heading_1">
                        <span style={{ fontSize: 19, color: "rgb(0, 0, 0)" }}>
                          <strong>QUESTIONS</strong>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.1" }}>
                        <br />
                      </div>
                      <div
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          If you have any questions concerning our return
                          policy, please contact us at:
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <bdt className="block-component" />
                        </span>
                      </div>
                      <div
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span style={{ fontSize: 15 }}>
                          <bdt className="question">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                            9306293768
                            </span>
                          </bdt>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                            &nbsp;
                          </span>
                        </span>
                      </div>
                      <div
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <bdt className="question">care@refuellabz.com</bdt>
                          &nbsp;
                        </span>
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;

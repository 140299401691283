export const initialState = {
    basket: [],
    wishlist: [],
};

const reducer = (state, action) => {
    console.log(action)
    switch (action.type) {
        case "ADD_TO_BASKET":
            return {
                ...state,
                basket: [...state.basket, action.item]
            }
        case "ADD_TO_WISHLIST":
            return {
              ...state,
              wishlist: [...state.wishlist, action.item],
            };
        case "REMOVE_FROM_BASKET":
            const index = state.basket.findIndex(
                (basketItem) => basketItem.id === action.id
            )
            let newBasket = [...state.basket]
            if(index>=0){
                newBasket.splice(index, 1);

            }else{
                console.warn(`Can't remove product ${action.id} as its not in the basket`)
            }

            return {
                ...state,
                basket:newBasket
            }

        default:
            return state;
    }
}

export default reducer;
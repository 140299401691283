import React, { useState, useEffect } from "react";
import "../App.css";
import "../assets/css/fontawesome.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/simple-line-icons.css";
// import "../assets/css/plugins/jquery-ui.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/plugins/plugins.css";
import "../assets/css/style.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logoDark from "../assets/img/logo/logoDark.png";
import logoW from "../assets/img/logo/logoW.png";
import icon1 from "../assets/img/icon/icon1.png";
import secure from "../assets/img/icon/secure.png";
import freeship from "../assets/img/icon/freeship.png";
import customer from "../assets/img/icon/customer.png";
import box from "../assets/img/icon/box.png";
import phone from "../assets/img/icon/phone.png";
import payment from "../assets/img/payment/payment.png";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { scaleDown as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";
import OffcanvasMenu from "react-offcanvas-menu-component";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Header";
import d from "../assets/img/icon/d.png";
import s from "../assets/img/icon/s.png";
import Footer from "../Components/Footer";
import HeaderScroll from "../Components/HeaderScroll";
import { useStateValue } from "../StateProvider";
import { db, auth } from "../firebase";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "red",
    boxShadow: "none",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function App() {
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate("/my-orders");
  };
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [refCodes, setRefCodes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mql = window.matchMedia("(max-width: 600px)");

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        console.log("user logged in");
        setEmail(authUser.email);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        alert('Please login/signup first.')
        navigate('/login');
      }
    });
  }, []);

  const [showN, setShowN] = useState(true);
  const [cartPrice, setCartPrice] = useState(0);
  const controlNavbar = () => {
    if (window.scrollY > 20) {
      setShowN(false);
    } else {
      setShowN(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const [{ basket }, dispatch] = useStateValue();
  var totalCartPrice = 0;

  for (var i = 0; i < basket.length; i++) {
    totalCartPrice += basket[i].discountedPrice;
  }
  // setCartPrice(totalCartPrice)

  useEffect(()=>{
    if(basket.length ==0) {
      alert('Please add something to your cart first.')
      navigate('/shop')
    }
  }, [])

  useEffect(()=>{
    setRefCodes([
      {code: 'FASHAL30', discount:30},
      {code: 'SAVVY30', discount:30},
      {code: 'MOHIT30', discount:30},
      {code: 'SHIPLP30', discount:30},
      {code: 'SAURABB30', discount:30},
      {code: 'AKSHAY30', discount:30},
      {code: 'ANCHAL30', discount:30},
      {code: 'SHUBH30', discount:30},
    ])
  }, [])

  const [prevD, setPrevD] = useState(0)

  const handlerRef = (event) => {
    let obj = refCodes.find(o => o.code === event.target.value.toUpperCase())
    if(obj != undefined) {
      var discount = obj.discount
      setPrevD(totalCartPrice/100*discount)
      totalCartPrice = totalCartPrice - totalCartPrice/100*discount
      setCartPrice(roundToTwo(totalCartPrice))
    } else {
      if(prevD!=0) {
        console.log(totalCartPrice, prevD)
        totalCartPrice = cartPrice + prevD
        setPrevD(0)
        setCartPrice(totalCartPrice)
      }
    }
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount, event) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Failed to load RazorPay. Please check your internet connection.");
      return;
    }

    const options = {
      key: "rzp_live_IB3Ua6erQxcXfP",
      currency: "INR",
      amount: amount * 100,
      name: "Refuel Labz",
      description: "Thank You for shopping with us.",
      image: "https://i.ibb.co/m0ym4b0/logoDark.jpg",

      handler: function (response) {
        // alert(response.razorpay_payment_id)
        console.log("Payment Successful");

        if (response.razorpay_payment_id) {
          db.collection("orders")
            .add({
              name: `${event.target.firstName.value} ${event.target.lastName.value}`,
              paymentId: response.razorpay_payment_id,
              orders: basket,
              number: event.target.phone.value,
              email: event.target.email.value,
              address: event.target.address.value,
              landmark: event.target.landmark.value
                ? event.target.landmark.value
                : " ",
              city: `${event.target.city.value}, ${event.target.state.value} - ${event.target.postcode.value}`,
              authenticatedEmail: email,
              status: "Order Under Process",
              time: new Date(),
            })
            .then((result) => {
              // console.log("Orders Sent");
              handleShow();
              sendEmail(event)
              document.getElementById("checkout-form").reset();
            })
            .catch((err) => console.log(err));
        }
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const checkoutUser = (event) => {
    event.preventDefault();

    if(loggedIn) {
      displayRazorpay(cartPrice || totalCartPrice, event);
    } else {
      navigate('/login');
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8o25fy7",
        "template_2i9902h",
        e.target,
        "6-U4w3xiIP5AZEXpk"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="Krishna-Main">
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Thank You for Shopping with Us!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center mb-8">
            Hi! We have successfully received your payment. Your orders are
            under process.{" "}
          </p>
          <p className="text-center mb-8">
            It usually takes 4-5 bussiness days for the shipment to be
            delivered. Feel free to contact us at our customer care no.
            9306293768 for any queries :-)
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showN ? <Header /> : <HeaderScroll />}
      <div>
        <nav
          className="breadcrumb-section theme1 bg-lighten2"
          style={{ paddingTop: "40px", paddingBottom: "30px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-15">
                  <h2 className="title text-dark text-capitalize">check out</h2>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* breadcrumb-section end */}
        {/* product tab start */}
        <section className="check-out-section pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-30 d-lg-none">
                <ul className="list-group cart-summary rounded-0">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <ul className="items">
                      <li>{basket.length} items</li>
                      <li>Shipping</li>
                    </ul>
                    <ul className="amount">
                      <li>₹ {cartPrice==0?totalCartPrice:cartPrice}</li>
                      <li>Free</li>
                    </ul>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <ul className="items">
                      <li>Total (tax incl.)</li>
                    </ul>
                    <ul className="amount">
                      <li>₹ {cartPrice==0?totalCartPrice:cartPrice}</li>
                    </ul>
                  </li>
                </ul>
                <div className="delivery-info mt-20">
                  <ul>
                    <li>
                      <img src={s} alt="icon" /> Secure Payment
                    </li>
                    <li>
                      <img src={d} alt="icon" /> Free & Superfast Delivery
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 mb-30">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed">
                          Billing Details
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo">
                      <div className="card-body">
                        <div className="checkout-inner border-0">
                          <div className="checkout-address p-0">
                            <p>
                              The following address will be used as your
                              delivery address.
                            </p>
                          </div>
                          <div className="check-out-content">
                            <form
                              id="checkout-form"
                              className="p-0"
                              onSubmit={checkoutUser}
                            >
                              <div className="form-group row">
                                <label
                                  className="col-md-3"
                                  htmlFor="firstName2"
                                >
                                  First name
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="firstName2"
                                    name="firstName"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="lastName2">
                                  Last name
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="lastName2"
                                    name="lastName"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="phone">
                                  Phone
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="phone">
                                  Email
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="email"
                                    type="email"
                                    name="email"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="address1">
                                  Address
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="address1"
                                    name="address"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="address2">
                                  Landmark
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="address2"
                                    name="landmark"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-3">
                                  {" "}
                                  <span className="optional">
                                    Optional
                                  </span>{" "}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="city">
                                  City
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3">State</label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="state"
                                    name="state"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="zip">
                                  Zip/Postal Code
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="zip"
                                    name="postcode"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3" htmlFor="referral">
                                  Referral Code
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="referral"
                                    name="referral"
                                    type="text"
                                    onChange={(e) => handlerRef(e)}
                                  />
                                </div>
                                <div className="col-md-3">
                                  {" "}
                                  <span className="optional">
                                    Optional
                                  </span>{" "}
                                </div>
                              </div>
                              {/* <div className="form-group row">
                                <label className="col-md-3">Country</label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    id="country"
                                    name="country"
                                    type="text"
                                    required
                                  />
                                </div>
                              </div> */}

                              <div className="form-group row mb-0 mt-5">
                                <div className="col-12 text-right">
                                  <button
                                    style={{
                                      backgroundColor: "#d9ffd8",
                                      color: "white",
                                      padding: "10px",
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                      fontSize: "19px",
                                      color: "#56c644",
                                      fontWeight: "bold",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <i className="fa fa-lock pr-3" />
                                    Checkout Securely
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-30 d-none d-lg-block">
                <ul className="list-group cart-summary rounded-0">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <ul className="items">
                      <li>{basket.length} items</li>
                      <li>Shipping</li>
                    </ul>
                    <ul className="amount">
                      <li>₹ {cartPrice==0?totalCartPrice:cartPrice}</li>
                      <li>Free</li>
                    </ul>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <ul className="items">
                      <li>Total (tax incl.)</li>
                    </ul>
                    <ul className="amount">
                      <li>₹ {cartPrice==0?totalCartPrice:cartPrice}</li>
                    </ul>
                  </li>
                </ul>
                <div className="delivery-info mt-20">
                  <ul>
                    <li>
                      <img src={s} alt="icon" /> Secure Payment
                    </li>
                    <li>
                      <img src={d} alt="icon" /> Free & Superfast Delivery
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product tab end */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
